<script>
import simplebar from "simplebar-vue";
import {VclCode} from "vue-content-loading";
import {with_acrf_token} from "@/methods";

export default {
  components: {
    simplebar,
    VclCode
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"]
      },
      notifications: null,
      error: false,
      ready: false
    };
  },
  methods: {
    onShow() {
      this.error = this.ready = false;
      this.notifications = null;
      this.getData();
    },
    hide() {
      this.$parent.toggleRightSidebar();
    },
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    middleware(event, el) {
      return !event.target.classList.contains("toggle-right");
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/notifications/list', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.notifications = data.notifications;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    markAsRead(_notification) {
      let notification = _notification; // Make available in entire scope
      //let toast = this.$toast.open('You did it!');
      notification.read = true;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          notification_id: notification.id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/notifications/read', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            notification.read = true;
          })
          .catch(error => {
            notification.read = false;
            this.$toast.error(this.$t('notifications.error.read'), {position: 'top-right'});
          });
      });
    }
  }
};
</script>

<template>
  <div>
    <div v-click-outside="config" class="right-bar">
      <div>
        <div class="rightbar-title px-3 py-4">
          <a href="javascript:void(0);" class="right-bar-toggle float-right" @click="hide">
            <i class="fas fa-times-circle  noti-icon"></i>
          </a>
          <h5 class="m-0">{{ $t('terms.notifications') }}</h5>
        </div>

        <hr class="mt-0 mb-0" />

        <template v-if="ready">
          <div v-if="notifications.length" style="overflow-y: auto; max-height: 93vh">
            <simplebar>
              <div v-for="notification in notifications" :key="notification.id">
                <div v-if="!notification.read" class="text-reset notification-item" v-on:click="markAsRead(notification)">
                  <div class="media">
                    <div class="avatar-xs mr-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="fad fa-info-circle"></i>
                    </span>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">{{ $t(notification.title, notification.arguments) }}</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">{{ $t(notification.content, notification.arguments) }}</p>
                        <p class="mb-0">
                          <i class="fad fa-clock"></i>
                          {{ $d(parseDate(notification.created_at), 'datetime') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-reset notification-item notification-item-disabled">
                  <div class="media">
                    <div class="avatar-xs mr-3">
                    <span class="avatar-title bg-light rounded-circle font-size-16">
                      <i class="fad fa-check-double"></i>
                    </span>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">{{ $t(notification.title, notification.arguments) }}</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">{{ $t(notification.content, notification.arguments) }}</p>
                        <p class="mb-0">
                          <i class="fad fa-clock"></i>
                          {{ $d(parseDate(notification.created_at), 'datetime') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
          <div v-else class="row">
            <div class="col-lg-12 ml-1 mt-2">
              <h5>{{ $t('notifications.none') }}</h5>
            </div>
          </div>

        </template>
        <template v-else-if="error">
          <div class="row">
            <div class="col-lg-12 align-self-center">
              <div class="card border border-danger">
                <div class="card-header bg-transparent border-danger">
                  <h5 class="my-0 text-danger">
                    <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
                  </h5>
                </div>
                <div class="card-body">
                  <h5 class="card-title mt-0">
                    {{ $t('error.server.generic.component') }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-lg-12 justify-content-start">
              <vcl-code class="ml-2 mt-2" :height="90"></vcl-code>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>

<style lang="scss"></style>
