export const menuItems = [
    {
        id: 1,
        label: "menu.dashboard.title",
        icon: "fad fa-house",
        link: "/dashboard",
        requiresSubscription: false,
    },
    {
        id: 2,
        label: "menu.support.title",
        icon: "fad fa-user-headset",
        link: "/support",
        requiresSubscription: false,
    },
    {
        id: 3,
        label: "menu.banmanager.title",
        icon: "far fa-ban",
        subItems: [
            {
                id: 3,
                label: "menu.banmanager.dashboard",
                link: "/ban-manager/dashboard",
                parentId: 3
            },

            {
                id: 3,
                label: "menu.banmanager.list",
                link: "/ban-manager/list",
                parentId: 4
            }

        ]
    },
];

